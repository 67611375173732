import React from 'react';
import './_hero-panel.scss';
import Button from '../Button/Button';

// Interfaces
import { IHeroPanelProps } from './HeroPanel.interface';

const HeroPanel: React.FunctionComponent<IHeroPanelProps> = ({ title, cta }: IHeroPanelProps) => {
    return (
        <div className="hero-panel">
            <div className="hero-panel__content">
                <h1>{title}</h1>
                {cta && 
                <Button 
                    text={cta.title}
                    type="link"
                    link={cta.link} />}
            </div>
        </div>
    );
}

export default HeroPanel;