import React from 'react';
import IconButton from '../IconButton/IconButton';
import './_modal.scss';

// Interfaces
import { IModalProps } from './Modal.interface';

const Modal: React.FunctionComponent<IModalProps> = ({ open, title, closeAction, children }: IModalProps) => {
    return (
        <>
            <div className={`modal-container ${open ? 'is-open': ''}`}>
                <div className="modal">
                    <div className="modal__header">
                        <h3>{title}</h3>
                        <IconButton icon="close" srText="Close Modal" action={() => closeAction()} />
                    </div>
                    <div className="modal__content">
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Modal;