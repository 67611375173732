import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import './_cake-list.scss';

// Interfaces
import { ICake, IUIContext } from './CakeList.interface';

// Components
import Icon from '../Icon/Icon';
import Card from '../Card/Card';
import Modal from '../Modal/Modal';
import CakeForm from '../CakeForm/CakeForm';

// Contexts
import { UIContext } from '../../Contexts/UIContext/UIContext';

const CakeList: React.FunctionComponent = () => {
    const uiContext: IUIContext = useContext(UIContext);
    const [cakes, setCakes] = useState<ICake[] | undefined>(undefined);
    const [cakeModal, setCakeModal] = useState<boolean>(false);

    const fetchCakes: Function = useCallback(async () => {
        uiContext.setLoading && uiContext.setLoading(true);

        await fetch(`https://murmuring-sea-45505.herokuapp.com/cakes`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data: ICake[] = await res.json();

            if(res.status === 200) {
                setCakes(data);
            } else {
                toast.error("There was an issue fething cakes. Please refresh and try again.");
            }

            uiContext.setLoading && uiContext.setLoading(false);
        })
        .catch(err => {
            toast.error("There was an issue fething cakes. Please refresh and try again.");
            uiContext.setLoading && uiContext.setLoading(false);
        });
    }, []);

    const handleCakeUpload: Function = (cake: ICake) => {
        let cakeList = cakes ? cakes : [];

        cakeList.push(cake);

        setCakes(cakeList);
        setCakeModal(false);
    }

    useEffect(() => {
        fetchCakes();
    }, [fetchCakes]);
    
    return (
        <>
        <div className="content-container">
            <h2 className="mb-1">Cake List</h2>
        </div>
        <div className="cake-container" id="cakes">
            <div className="card-container">
                {cakes && cakes.map((cake, index) =>
                    <Card title={cake.name} image={cake.imageUrl} link={`/cake/${cake._id}`} key={index} />
                )}
                <button className="card card--addition" onClick={() => setCakeModal(true)}>
                    <div className="card__image">
                        <Icon icon="plus" />
                    </div>
                    <div className="card__title">Add New Cake</div>
                </button>
            </div>
        </div>
        <Modal open={cakeModal} title="Add Cake" closeAction={() => setCakeModal(false)}>
            {cakeModal && <CakeForm onCompletion={(data: ICake) => handleCakeUpload(data) } />}
        </Modal>
        </>
    );
}

export default CakeList;