import React from 'react';

// Interfaces
import { IMainLayoutProps } from './MainLayout.interface';

// Components
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Loader from '../../Components/Loader/Loader';

const MainLayout: React.FunctionComponent<IMainLayoutProps> = ({children}: IMainLayoutProps) => {
    return (
        <>
            <Loader />
            <Header />
            {children}
            <Footer />
        </>
    );
}

export default MainLayout;