import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './_header.scss';

// Components
import IconButton from '../IconButton/IconButton';

// Contexts
import { UIContext } from '../../Contexts/UIContext/UIContext';

const Header: React.FunctionComponent = () => {
    const history = useHistory();
    const [navChange, setNavChange] = useState<boolean>(false);
    const [menu, setMenu] = useState<boolean>(false);

    useEffect(() => {
        let listener: any = null;
        
        listener = document.addEventListener("scroll", () => {
            let pos: number = window.scrollY;

            if(pos > 99) {
                setNavChange(true);
            } else {
                setNavChange(false);
            }
        });

        return () => {
            document.removeEventListener("scroll", listener);
        }
    }, [navChange]);

    useEffect(() => {
        setMenu(false);
    }, [history.location]);

    return (
        <header className={`site-header ${navChange ? 'is-solid' : ''}`}>
            <div className="site-header__inner">
                <Link to="/" className="site-logo">CakeKing</Link>
                <nav className={`site-header__nav ${menu ? 'is-open' : ''}`}>
                    <IconButton icon={menu ? 'close' : 'menu'} srText="Toggle Menu" action={() => setMenu(!menu)} />
                    <ul className="site-menu">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;