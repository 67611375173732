import React, { useContext } from 'react';
import './_loader.scss';

// Contexts
import { UIContext } from '../../Contexts/UIContext/UIContext';

// Interfaces
import { IUIContext } from './Loader.interface';

const Loader: React.FunctionComponent = () => {
    const uiContext: IUIContext = useContext(UIContext);
    
    return (
        <>
            {uiContext && uiContext.state && uiContext.state.loading &&
                <div className="loader">
                    <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            } 
        </> 
    );
}

export default Loader;