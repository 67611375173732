import React from 'react';
import { useHistory } from 'react-router-dom';
import './_go-back.scss';
import IconButton from '../IconButton/IconButton';

const GoBackButton: React.FunctionComponent = () => {
    const history = useHistory();

    return (
        <div className="page-nav">
            <IconButton icon="chevron-left" srText="Go Back" action={() => history.goBack()} />
            <span className="ml-1">Back</span>
        </div>
    );
}

export default GoBackButton;