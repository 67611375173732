import React from 'react';
import Icon from '../Icon/Icon';
import './_icon-button.scss';

// Interfaces
import { IIconButtonProps } from './IconButton.interface';

const IconButton: React.FunctionComponent<IIconButtonProps> = ({ icon, srText, action }: IIconButtonProps) => {
    return (
        <button className="icon-btn" onClick={() => action()}>
            <span className="sr-only">{srText}</span>
            <Icon icon={icon}></Icon>
        </button>
    );
}

export default IconButton;