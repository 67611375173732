import React from 'react';
import { IButtonProps } from './Button.interface';
import './_button.scss';

const Button: React.FunctionComponent<IButtonProps> = ({ text, type, action, link, colour }: IButtonProps) => {
    return (
        <>
        {type === "link" ?
            <a href={link ? link : '/'} className={`btn ${colour ? colour : ''}`}>
                {text}
            </a>
        :
            <button className={`btn ${colour ? colour : ''}`} onClick={() => action && action()}>
                {text}
            </button>
        }
        </>
    );
}

export default Button;