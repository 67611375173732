import React from 'react';

// Components
import CakeList from '../../Components/CakeList/CakeList';
import HeroPanel from '../../Components/HeroPanel/HeroPanel';

const Home: React.FunctionComponent = () => {
    return (
        <>
            <HeroPanel 
                title="Cake. It's what we do."
                cta={{
                    title: "Browse Cakes",
                    link: "#cakes"
                }} />
            <CakeList />
        </>
    );
}

export default Home;