import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import './_cake-form.scss';

// Interfaces
import { ICake, ICakeFormProps } from '../CakeForm/CakeForm.interface';

// Validation
import { CakeFormShape } from './CakeFormValidation';

// Components
import Icon from '../Icon/Icon';
import Button from '../Button/Button';

const CakeForm: React.FunctionComponent<ICakeFormProps> = ({ onCompletion }: ICakeFormProps) => {
    const [rating, setRating] = useState<number>(1);
    const [error, setError] = useState<undefined | string>(undefined);

    const submitNewCake: Function = async (data: ICake) => {
        setError(undefined);

        await fetch(`https://murmuring-sea-45505.herokuapp.com/cakes`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({...data, yumFactor: rating})
        })
        .then(async res => {
            let data = await res.json();
            if(res.status === 200) {
                toast.success("New cake has been added!");
                onCompletion(data);
            } else if (res.status === 500) {
                if(data.err.code === 11000) {
                    setError("A cake with his name already exists. Please enter a different name.");
                }
            } else {
                toast.error("There was an issue adding new cake. Please refresh and try again.");
            }
        })
        .catch(err => {
            toast.error("There was an issue adding new cake. Please refresh and try again.");
        });
    }

    return (
        <Formik
            initialValues={{
                name: "",
                comment: "",
                imageUrl: "",
                yumFactor: 1,
            }}
            validationSchema={CakeFormShape}
            onSubmit={(data: ICake) => submitNewCake(data)}>
                {({ errors, touched }) => (
                    <Form>
                        <div className="split-form">
                            <div className="split-form__section">
                                <div className="form-item">
                                    <label>Name</label>
                                    <Field type="text" name="name" id="name" placeholder="Enter cake name..." />
                                    {errors.name && touched.name ? <div className="form-error">{errors.name}</div> : null}
                                </div>
                                <div className="form-item">
                                    <label>Comment</label>
                                    <Field component="textarea" name="comment" id="comment" placeholder="Enter cake review..." />
                                    {errors.comment && touched.comment ? <div className="form-error">{errors.comment}</div> : null}
                                </div>
                            </div>
                            <div className="split-form__section">
                                <div className="form-item">
                                    <label>Image (URL)</label>
                                    <Field type="text" name="imageUrl" id="imageUrl" placeholder="Enter cake image url..." />
                                    {errors.imageUrl && touched.imageUrl ? <div className="form-error">{errors.imageUrl}</div> : null}
                                </div>
                                <div className="form-item">
                                    <label>Yum Factor</label>
                                    <div className="rating">
                                        <span className="rating__item">
                                            <Field type="radio" value="1" name="yumFactor" id="yumFactor" onChange={() => setRating(1)} />
                                            <Icon icon={rating > 0 ? 'star-filled' : 'star-empty'} />
                                        </span>
                                        <span className="rating__item">
                                            <Field type="radio" value="2" name="yumFactor" id="yumFactor" onChange={() => setRating(2)} />
                                            <Icon icon={rating >= 2 ? 'star-filled' : 'star-empty'} />
                                        </span>
                                        <span className="rating__item">
                                            <Field type="radio" value="3" name="yumFactor" id="yumFactor" onChange={() => setRating(3)} />
                                            <Icon icon={rating >= 3 ? 'star-filled' : 'star-empty'} />
                                        </span>
                                        <span className="rating__item">
                                            <Field type="radio" value="4" name="yumFactor" id="yumFactor" onChange={() => setRating(4)} />
                                            <Icon icon={rating >= 4 ? 'star-filled' : 'star-empty'} />
                                        </span>
                                        <span className="rating__item">
                                            <Field type="radio" value="5" name="yumFactor" id="yumFactor" onChange={() => setRating(5)} />
                                            <Icon icon={rating >= 5 ? 'star-filled' : 'star-empty'} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {error && <div className="form-error mb-1">{error}</div>}
                        <Button text="Submit" type="functional" action={() => null} colour="accent" />
                    </Form>
                )}
        </Formik>
    );
}

export default CakeForm;