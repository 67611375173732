import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// Components
import CakeDetailCard from '../../Components/CakeDetailCard/CakeDetailCard';
import GoBackButton from '../../Components/GoBackButton/GoBackButton';

// Contexts
import { UIContext } from '../../Contexts/UIContext/UIContext';

// Interfaces
import { ICake, IParams, IUIContext } from './CakeDetail.interface';

const CakeDetail: React.FunctionComponent = () => {
    const uiContext: IUIContext = useContext(UIContext);
    const { id }: IParams = useParams();
    const [cake, setCake] = useState<ICake | undefined>(undefined);

    const fetchCakeDetail = useCallback(async () => {
        uiContext.setLoading && uiContext.setLoading(true);

        await fetch(`https://murmuring-sea-45505.herokuapp.com/cakes/${id}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data: ICake = await res.json();

            if(res.status === 200) {
                setCake(data);
            } else {
                toast.error("There was an issue fetching cake detail.");
            }

            uiContext.setLoading && uiContext.setLoading(false);
        })
        .catch(err => {
            toast.error("There was an issue fetching cake detail.");
            uiContext.setLoading && uiContext.setLoading(false);
        });
    }, [id]);

    useEffect(() => {
        fetchCakeDetail();
    }, [fetchCakeDetail]);
    return (
        <div className="content-container nav-spacer">
            <GoBackButton />
            {cake &&
                <CakeDetailCard cake={cake} />
            }
        </div>
    );
}

export default CakeDetail;