import React from 'react';
import { Link } from 'react-router-dom';
import './_card.scss';

// Interfaces
import { ICardProps } from './Card.interface';

const Card: React.FunctionComponent<ICardProps> = ({ title, image, link }: ICardProps) => {
    return (
        <Link to={link} className="card">
            <div className="card__image">
                <img src={image} alt={title} />
            </div>
            <div className="card__title">{title}</div>
        </Link>
    );
}

export default Card;