import React from 'react';
import './_footer.scss';

// Components
import Icon from '../Icon/Icon';

const Footer: React.FunctionComponent = () => {
    return (
        <footer className="site-footer">
            <p>Made with <Icon icon="cake" /></p>
        </footer>
    );
}

export default Footer;