import React, { createContext, useState } from 'react';

// Interfaces
import { IUIProviderProps } from './UIContext.interface';

const UIContext = createContext({});

const UIProvider = ({ children}: IUIProviderProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <UIContext.Provider value={{
            state: {
                loading,
            },
            setLoading,
        }}>
            {children}
        </UIContext.Provider>
    )
}

const UIConsumer = UIContext.Consumer;
export { UIContext, UIProvider, UIConsumer };