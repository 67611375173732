import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Containers
import Home from './Containers/Home/Home';
import CakeDetail from './Containers/CakeDetail/CakeDetail';

// Layouts
import MainLayout from './Layouts/MainLayout/MainLayout';
import { UIProvider } from './Contexts/UIContext/UIContext';

function App() {
  return (
    <>
      <UIProvider>
        <Router>
          <Switch>
            <MainLayout>
              <Route path="/" exact component={Home} />
              <Route path="/cake/:id" exact component={CakeDetail} />
            </MainLayout>
          </Switch>
          <ToastContainer />
        </Router>
      </UIProvider>
    </>
  );
}

export default App;
