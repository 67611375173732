import React from 'react';
import './_cake-detail.scss';

// Interfaces
import { ICakeDetailCardProps } from './CakeDetail.interface';
import Icon from '../Icon/Icon';

const CakeDetailCard: React.FunctionComponent<ICakeDetailCardProps> = ({ cake }: ICakeDetailCardProps) => {
    return (
        <div className="cake-detail">
            <div className="cake-detail__image">
                <img src={cake.imageUrl} alt={cake.name} />
            </div>
            <div className="cake-detail__content">
                <h1 className="mb-1">{cake.name}</h1>
                <h3>Review</h3>
                <p className="mb-1">{cake.comment}</p>
                <h3 className="mt-auto">Yum Factor</h3>
                <div className="rating">
                    <span className="rating__item">
                        <Icon icon={cake.yumFactor > 0 ? 'star-filled' : 'star-empty'} />
                    </span>
                    <span className="rating__item">
                        <Icon icon={cake.yumFactor >= 2 ? 'star-filled' : 'star-empty'} />
                    </span>
                    <span className="rating__item">
                        <Icon icon={cake.yumFactor >= 3 ? 'star-filled' : 'star-empty'} />
                    </span>
                    <span className="rating__item">
                        <Icon icon={cake.yumFactor >= 4 ? 'star-filled' : 'star-empty'} />
                    </span>
                    <span className="rating__item">
                        <Icon icon={cake.yumFactor >= 5 ? 'star-filled' : 'star-empty'} />
                    </span>
                </div>
            </div>
        </div>
    );
}

export default CakeDetailCard;