import React from 'react';

// Interfaces
import { IIconProps } from './Icon.interface';

const Icon: React.FunctionComponent<IIconProps> = ({ icon }: IIconProps) => {
    return (
        <svg>
            <use xlinkHref={`/assets/icon-library.svg#${icon}`} />
        </svg>
    );
}

export default Icon;